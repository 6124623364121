import React from 'react'
import { Link } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'

import SEO from '../components/seo'
import Nav from '../components/Nav'
import Superscript from '../components/Superscript'
import Footer from '../components/Footer'

import background from '../assets/design-sprint-background.jpg'
import portrait from '../assets/karl-sprint4.jpg'

class Sprint extends React.Component {
  render() {
    return (
      <Container>
        <SEO title="Hire a Design Sprint Facilitator" />
        <Nav selected="sprint" selectedcolor="#fed330" />
        <SprintBackground>
          <Wrapper>
            <SprintSuperscript>The Design Sprint.</SprintSuperscript>
            <Headline>
              <p>
                Spend one week to define your product by answering critical
                business questions through good design, prototyping, and
                testing.
              </p>
            </Headline>
            <More>
              <p>
                Hire an experienced design sprint facilitator that will help
                guide your team to success through good design & product
                strategy.
              </p>
              <p>
                Interested in a Design Sprint?
                <br />→{' '}
                <a href="mailto:sprint@danninger.co">sprint@danninger.co</a>
              </p>
            </More>
          </Wrapper>
        </SprintBackground>
        <ContentContainer>
          <Wrapper>
            <Content>
              <p>
                The concept of a design sprint is not a new one. Growing
                originally from the roots of Agile and Lean methodologies,
                companies have been utilizing their own versions of a design
                sprint over the last decade. We can thank Jake Knapp and his
                amazing work at Google Ventures for bringing its principles to a
                larger audience.
              </p>
              <p>
                The main focus of the design sprint is to get validation
                immediately by the end of the sprint. A side-effect of this is
                you get actionable goals based on vision, and success metrics
                that are measurable.
              </p>
              <p>
                However you may use the design sprint framework, it brings
                clarity and validation to almost any new or existing product.
              </p>
              <p>
                <a
                  href="https://medium.okgrow.com/build-the-right-thing-first-with-design-sprints-4d1663166fe9"
                  target="_blank"
                >
                  More on Design Sprints
                </a>
              </p>
            </Content>
          </Wrapper>
        </ContentContainer>
        <ContentContainer style={{ backgroundColor: 'rgb(27,27,27)' }}>
          <Wrapper>
            <Content style={{ color: 'white' }}>
              <Portrait src={portrait} />
              <h3>Meet your facilitator</h3>
              <Small>
                LAST EDITED - JANUARY 25, 2019 <sup>*</sup>
              </Small>
              <p>
                Karl Danninger is a multi-disciplinary developer and designer
                for the modern web.
              </p>
              <p>
                Focusing on human-centered design, he crafts beautiful and
                functional experiences that are a joy to use.
              </p>
              <p>
                Being both a developer and designer, Karl benefits from the
                understanding between good design practices and the underlying
                tech that drives rich applications.
              </p>
              <p>
                Karl has facilitated 15<sup>*</sup>{' '}
                <a
                  href="https://www.okgrow.com/posts/build-the-right-thing-first-with-design-sprints"
                  target="_blank"
                  style={{ color: 'white', borderColor: 'white' }}
                >
                  design sprints
                </a>
                , that have directed teams towards success.
              </p>
              <p>
                Ready to start a Design Sprint?
                <br />
                <SprintCTA>
                  → <a href="mailto:sprint@danninger.co">sprint@danninger.co</a>
                </SprintCTA>
              </p>
            </Content>
          </Wrapper>
        </ContentContainer>
        <Footer />
      </Container>
    )
  }
}

export default Sprint

// const textShadowColor = '#3f51b5'
const textShadowColor = '#fed330'

const SprintSuperscript = styled(Superscript)`
  font-size: 165px;
  margin-left: -3px;
  ::-moz-selection {
    text-shadow: 1px 1px ${textShadowColor}, 2px 2px ${textShadowColor},
      3px 3px ${textShadowColor}, 4px 4px ${textShadowColor},
      5px 5px ${textShadowColor}, 6px 6px ${textShadowColor},
      7px 7px ${textShadowColor}, 8px 8px ${textShadowColor},
      9px 9px ${textShadowColor}, 10px 10px ${textShadowColor};
  }
  ::selection {
    text-shadow: 1px 1px ${textShadowColor}, 2px 2px ${textShadowColor},
      3px 3px ${textShadowColor}, 4px 4px ${textShadowColor},
      5px 5px ${textShadowColor}, 6px 6px ${textShadowColor},
      7px 7px ${textShadowColor}, 8px 8px ${textShadowColor},
      9px 9px ${textShadowColor}, 10px 10px ${textShadowColor};
  }
  @media (max-width: 1000px) {
    margin-left: -0.3vw;
    font-size: 16.5vw;
  }
`

const SprintCTA = styled.span`
  && {
    color: ${textShadowColor};
    a {
      color: ${textShadowColor};
      border-color: ${textShadowColor};
    }
  }
`

const Small = styled.span`
  font-size: 10px;
  letter-spacing: 1px;
  color: rgb(141, 141, 141);
  font-family: Inter, sans-serif;
  sup {
    font-size: 12px !important;
    top: 0;
  }
`

const SprintBackground = styled.div`
  width: 100%;
  top: 0;
  margin-top: -200px;
  padding-top: 200px;
  display: flex;
  justify-content: center;
  background-image: url(${background});
  background-size: cover;
  background-position: 50%;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
  flex-direction: column;
  a {
    color: white;
    text-decoration: none;
    box-shadow: none;
    white-space: nowrap;
    border-bottom: 3px solid rgba(255, 255, 255, 1);
    @media (max-width: 680px) {
      border-bottom: 2px solid rgba(255, 255, 255, 1);
    }
  }
  @media (max-width: 680px) {
    padding-top: 150px;
  }
`

const Content = styled.div`
  color: rgb(27, 27, 27);
  font-size: 30px;
  line-height: 1.6;
  font-weight: 400;
  font-family: Inter, sans-serif;
  margin-left: 25%;
  h3 {
    font-size: 30px;
    font-weight: 800;
    @media (max-width: 680px) {
      margin-bottom: 0.8rem;
    }
  }
  sup {
    color: red;
    font-size: 20px;
    font-family: Inter;
  }
  a {
    color: #3498db;
    border-bottom: 3px solid #3498db;
  }
  @media (max-width: 680px) {
    font-size: 18px;
    margin-left: 0;
    a {
      border-bottom: 2px solid #3498db;
    }
  }
`

const ContentContainer = styled.div`
  background-color: white;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 100px 0;
  @media (max-width: 680px) {
    padding: 50px 0;
  }
`

const Wrapper = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 0 24px;
  > p {
    // padding-bottom: 100px;
  }
  @media (max-width: 1000px) {
    width: auto;
    margin: 0 5vw;
  }
  @media (max-width: 680px) {
    > p {
      // padding-bottom: 50px;
    }
  }
`

const Portrait = styled.img`
  float: left;
  width: 50%;
  margin-left: -33.3333%;
  margin-right: 50px;
  margin-bottom: 25px;
  // filter: grayscale(100%);
  // transition: filter 300ms ease;
  // &:hover {
  //  filter: grayscale(0%);
  // }
  @media (max-width: 680px) {
    width: 82px;
    margin-right: 30px;
    margin-bottom: 10px;
    margin-left: 0%;
  }
`

const Links = styled.span`
  font-family: Inter, sans-serif;
  color: rgb(27, 27, 27);
  margin: 40px 0;
`

const LinkTag = styled.a`
  font-size: 14px;
  color: white;
  text-decoration: none;
  box-shadow: none;
  white-space: nowrap;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
`

const Headline = styled.h1`
  color: white;
  font-size: 30px;
  line-height: 1.6;
  font-weight: 400;
  font-family: Inter, sans-serif;
  margin: 100px 0 0;
  margin-left: 25%;
  p::-moz-selection {
    background-color: ${textShadowColor};
  }
  p::selection {
    background-color: ${textShadowColor};
  }
  a::-moz-selection {
    background-color: ${textShadowColor};
  }
  a::selection {
    background-color: ${textShadowColor};
  }
  @media (max-width: 680px) {
    font-size: 18px;
    margin-top: 50px;
  }
`

const More = styled.h1`
  color: white;
  font-size: 30px;
  line-height: 1.6;
  font-weight: 400;
  font-family: Inter, sans-serif;
  margin: 0 0 100px;
  margin-left: 25%;
  p::-moz-selection {
    background-color: ${textShadowColor};
  }
  p::selection {
    background-color: ${textShadowColor};
  }
  a::-moz-selection {
    background-color: ${textShadowColor};
  }
  a::selection {
    background-color: ${textShadowColor};
  }
  @media (max-width: 680px) {
    font-size: 18px;
    margin-bottom: 50px;
  }
`
